/* Global Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body, html {
    font-family: "Inter", Arial, sans-serif;
    height: 100%;
    color: #333;
    background: #f9f9f9;
    min-height: 100vh;
    line-height: 1.6;
}

main {
    width: 80%;
    max-width: 1200px;
    padding: 2rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

/* Centered Content for Homepage */
.homepage, .section-container, .blog-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: 100vh;
    padding: 2rem;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: 100vh;
    padding: 2rem;
}

.section {
    margin-bottom: 4rem;
}

h1, h2, h3 {
    font-weight: 700;
    color: #222;
    margin-bottom: 1rem;
}

p, a {
    font-size: 1.1rem;
    color: #555;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}


/* Navigation Bar */
.header {
    width: 100%;
    background: #333;
    padding: 1rem 2rem;
    color: white;
    text-align: left;
    position: sticky;
    top: 0;
}

.main-menu {
    list-style: none;
    display: flex;
    gap: 2rem;
    justify-content: center;
}

.menu-item {
    position: relative;
}

.menu-item a {
    color: white;
    font-size: 1.2rem;
}

.menu-item a:hover {
    text-decoration: underline;
}

.menu-item button {
    background: none;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    background: #333;
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 10px 0;
    border-radius: 5px;
    min-width: 150px;
}

.dropdown li a, .navigation a {
    color: white;
    text-decoration: none;
    padding: 5px 15px;
}

.dropdown li:hover {
    background: #555;
}

/* Featured Blogs */
.featured-blogs, .blog-list {
    max-width: 800px;
    width: 100%;
    margin: 40px 0;
    text-align: left;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 1.0);
    padding: 2rem;
    background: white;
    border-radius: 8px;
}

.blog-card, .blog-item {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: left;
}

.blog-card h3, .blog-list h1 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.blog-card p, .blog-post p {
    font-size: 1rem;
    color: #666;
}

.blog-card a, .read-more {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
}

.blog-card a:hover, .read-more:hover {
    text-decoration: underline;
}

/* Introduction Section */
.introduction {
    max-width: 600px;
    padding: 20px;
    margin-bottom: 2rem;
}

.introduction h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
}

.introduction p {
    font-size: 1.25rem;
    margin-bottom: 1rem;
}

.hero {
    padding: 4rem 0;
}


/* Footer and Social Links */
.footer {
    width: 100%;
    text-align: center;
    padding: 20px 0;
    font-size: 0.9rem;
}

.social-links a {
    margin: 0 20px;
}

/* Blog Post Styles */

.blog-section {
    width: 100%;
    padding: 2rem 0;
    max-width: 800px;
    text-align: left;
    margin-top: 0;
}

.blog-card {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.blog-card p {
    color: #555;
    margin-bottom: 1rem;
}

.blog-card a {
    font-weight: bold;
    color: #007bff;
    text-decoration: none;
}

.blog-post {
    padding: 20px;
    max-width: 800px;
    margin: auto;
}

.blog-post h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.blog-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
}

/* Profile Picture */
.profile-pic {
    width: 150px;
    height: 150px;
    border-radius: 50%;
}

/* Media Queries */
@media (max-width: 768px) {
    .main-menu {
        flex-direction: column;
        gap: 1rem;
    }

    h1, h2 {
        font-size: 2rem;
    }

    .blog-grid {
        grid-template-column: 1fr;
    }

    .introduction h1 {
        font-size: 2.5rem;
    }

    .hero h1, .blog-post h1 {
        font-size: 2rem;
    }

    .hero p, .blog-post p {
        font-size: 1rem;
    }
}
