.header {
    background-color: #333;
    padding: 20px 0;
    text-align: left;
    justify-content: left;
    align-items: center;
    display: flex;
}

.header .navbar {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}

.header .main-menu {
    list-style: none;
    display: flex;
    gap: 20px;
    align-items: center;
    flex-wrap: wrap;
    margin: 20px;
}

.menu-item {
    position: relative;
    justify-content: flex-start;
    border-radius: 20px;
}

.menu-item button,
.menu-item a {
    background: none;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    padding: 5px 10px;
    text-align: left;
    text-decoration: none;
}

.menu-item .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #333;
    padding: 10px 0;
    display: none;
    list-style: none;
    min-width: 150px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    flex-direction: column;
}

.menu-item .dropdown li {
    padding: 5px 15px;
}

.menu-item .dropdown li a {
    color: white;
    text-decoration: none;
}

.menu-item:hover .dropdown,
.menu-item:focus-within .dropdown {
    display: flex;
}

.dropdown li:hover {
    background-color: #555;
}

@media (max-width: 768px) {
    .main-menu {
        flex-direction: column;
        gap: 10px;
    }
}